import { ReactNode, useState } from 'react';
import { useUser } from 'stores/userStore';
import { useUserEntities } from 'stores/userEntitiesStore';
import { favoriteTeams } from 'actions/user_entities_actions';
import styles from './styles/Header.module.scss';
import Link from 'components/Link';
import cn from 'classnames';
import Dots from 'icons/Dots';
import Search from 'components/Search';
import SearchIcon from 'icons/Search';
import HeaderSearchMenu, { displayParser } from './HeaderSearchMenu';
import PasswordModal from 'components/PasswordModal';
import Account from 'icons/Account';
import Star from 'icons/Star';
import HeaderStatesMenu from './HeaderStatesMenu';
import HeaderGenderSportsMenuNew from './HeaderGenderSportsMenu';
import HeaderFavoritesMenuNew from './HeaderFavoritesMenu';
import HeaderAccountMenuNew from './HeaderAccountMenu';
import useScreenSize from 'hooks/useScreenSize';
import Modal from 'components/Modal';
import {
  BASEBALL,
  BOYS_BASKETBALL,
  findGenderSportBySlug,
  FOOTBALL,
  GIRLS_BASKETBALL,
  GIRLS_VOLLEYBALL,
  SOFTBALL
} from 'constants/GenderSports';

import ScreenSizes, { ScreenSize } from '@/components/ScreenSizes';
import queries from 'queries';
import { selectTypename } from '@sblive-sports/api-client-js';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import AccountDeleteModal from 'components/RequestAccountDelete';
import Clickable from '@/components/Clickable';
import { useHeaderContainer } from '..';
import StateFlag from '@/components/StateFlag';
import { makeUrl } from 'helpers/url';
import SBLiveOnSI from 'icons/SBLiveOnSI';
import SBLiveLogoWhite from 'images/icons/sblive-logo-white.png';
import FlexContainer from '@/components/FlexContainer';

type MenuType = 'account' | 'states' | 'sports' | 'search' | 'favorites' | '';

interface ImageSelectProps {
  isRight?: boolean;
  component?: ReactNode;
  className?: string;
  handleClick?: () => void;
  children: ReactNode;
  autoId?: string;
  isCenter?: boolean;
}

const ImageSelect = ({ children, isRight, component, className, autoId, handleClick, isCenter }: ImageSelectProps) => {
  return (
    <div data-automation-id={autoId} className={cn(className, styles.ImageSelect)} onClick={handleClick}>
      {children}
      {component && <div className={styles.MenuBuffer} />}
      <div className={cn(styles.ImageSelectMenu, { [styles.ImageSelectMenuRight]: isRight, [styles.ImageSelectMenuCenter]: isCenter })}>
        {component}
      </div>
    </div>
  );
};

function GenderSportLink({ genderSportSlug }: { genderSportSlug: string }) {
  const [{ genderSport: currentGenderSport }] = useApplicationContainer();
  const genderSport = findGenderSportBySlug(genderSportSlug)!;
  const [, { makeGenderSportHref }] = useHeaderContainer();

  const name = genderSport.name.replace('Girls', 'G.').replace('Boys', 'B.');

  return (
    <Link href={makeGenderSportHref({ genderSport })} className={cn({ [styles.ActiveSport]: currentGenderSport?.key === genderSport.key })}>
      {name}
    </Link>
  );
}

export default function HeaderNav() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
  const [mobileMenu, setMobileMenu] = useState<MenuType>('account');
  const userData = useUser();
  const userEntitiesData = useUserEntities();
  const { isSmallScreen, isLargeScreen, isXtraLargeScreen } = useScreenSize();
  const [{ state, genderSport }, { handleOpenLoginModal, makePath, makeHomePath }] = useApplicationContainer();

  const isLoggedIn = userData.isLoggedIn;

  const favorites = favoriteTeams(userEntitiesData.userEntities);
  //const showFavoriteTeams = favorites && favorites.length > 0;
  //
  //const envClassName = ''; //TODO need to uncomment above before production push
  //
  //useEffect(() => {
  //  const id = setInterval(() => {
  //    if (!document) return;
  //    const googleAds = document.getElementsByClassName('adsbygoogle');
  //    if (!googleAds || googleAds.length == 0) return;
  //    for (let i = 0; i < googleAds.length; i++) {
  //      const current: any = googleAds[i];
  //      if (!current || !current.style || !current.dataset || !document.body) continue;
  //      if (current.style.top == '0px' && current.style.bottom == 'auto' && current.dataset.anchorShown) {
  //        current.style.display = 'none';
  //        document.body.style.paddingTop = '0px';
  //      }
  //    }
  //  }, 100);
  //
  //  return () => clearInterval(id);
  //}, []);

  function MobileMenu({ mobileMenu, handleMobileMenuClose, handleOpenPassword }: any) {
    let title = '';
    if (mobileMenu === 'states') title = 'Select State';
    if (mobileMenu === 'sports') title = 'Select Sport';
    if (mobileMenu === 'account') title = 'Account';
    if (mobileMenu === 'search') title = 'School Search';
    if (mobileMenu === 'favorites') title = 'Following';
    return (
      <Modal onClose={handleMobileMenuClose} isOpen scrollable title={title}>
        {mobileMenu === 'states' && <HeaderStatesMenu />}
        {mobileMenu === 'sports' && <HeaderGenderSportsMenuNew />}
        {mobileMenu === 'account' && (
          <HeaderAccountMenuNew handleOpenPassword={handleOpenPassword} handleOpenAccountDelete={handleOpenAccountDelete} />
        )}
        {mobileMenu === 'search' && <HeaderSearchMenu />}
        {mobileMenu === 'favorites' && <HeaderFavoritesMenuNew favorites={favorites} />}
      </Modal>
    );
  }

  const handleMobileMenuClick = (menuType: MenuType) => {
    setMobileMenu(menuType);
    setShowMobileMenu(true);
  };

  const handleMobileMenuClose = () => setShowMobileMenu(false);
  const handleOpenPassword = () => setShowPasswordModal(true);
  const handleOpenAccountDelete = () => setShowAccountDeleteModal(true);

  function handleSearch(query: string) {
    return queries.searchClientQuery({ query }).then(({ search: { nodes } }) => {
      return nodes.filter(selectTypename('School'));
    });
  }

  const inDefaultSports =
    !genderSport || [FOOTBALL, BOYS_BASKETBALL, GIRLS_BASKETBALL, BASEBALL, SOFTBALL, GIRLS_VOLLEYBALL].includes(genderSport.slug);
  const inDefaultSportsMobile = !genderSport || [FOOTBALL, BOYS_BASKETBALL].includes(genderSport.slug);

  return (
    <nav className={styles.Nav}>
      {/*<VignetteSponsor />*/}
      {showPasswordModal && <PasswordModal onClose={() => setShowPasswordModal(false)} isOpen={showPasswordModal} />}
      {showAccountDeleteModal && (
        <AccountDeleteModal
          onClose={() => setShowAccountDeleteModal(false)}
          isOpen={showAccountDeleteModal}
          username={userData.account?.username}
        />
      )}
      {showMobileMenu && (
        <MobileMenu
          mobileMenu={mobileMenu}
          handleMobileMenuClose={handleMobileMenuClose}
          handleOpenPassword={handleOpenPassword}
          handleOpenAccountDelete={handleOpenAccountDelete}
        />
      )}
      <ScreenSizes hide={ScreenSize.SMALL}>
        <div className={styles.TopNav}>
          <div className={styles.LinkBlock}>
            <Link href="https://www.si.com/" target="_blank" rel="noopener" className={cn(styles.TextLink, styles.ExcludedBlock)}>
              SI.COM
            </Link>
            <Link href="https://www.si.com/fannation/" target="_blank" rel="noopener" className={cn(styles.TextLink, styles.ExcludedBlock)}>
              ON SI
            </Link>
            <Link
              href="https://www.sitickets.com/category/sports?utm_source=si&utm_medium=referral&utm_content=main-nav"
              target="_blank"
              rel="noopener"
              className={cn(styles.TextLink, styles.ExcludedBlock)}
            >
              SI TICKETS
            </Link>
            <Link href="https://www.sisportsbook.com/" target="_blank" rel="noopener" className={cn(styles.TextLink, styles.ExcludedBlock)}>
              SI SPORTSBOOK
            </Link>
            <Link
              href="https://gofan.co/?&utm_medium=referral&utm_source=scorebooklive"
              target="_blank"
              rel="noopener"
              className={cn(styles.TextLink, styles.ExcludedBlock)}
            >
              GO FAN
            </Link>
            <Link
              href="https://www.nfhsnetwork.com/?&utm_medium=referral&utm_source=scorebooklive"
              target="_blank"
              rel="noopener"
              className={cn(styles.TextLink, styles.ExcludedBlock)}
            >
              NFHS NETWORK
            </Link>
            <Link
              href="https://www.si.com/high-school/stats/about"
              target="_blank"
              rel="noopener"
              className={cn(styles.TextLink, styles.ExcludedBlock)}
            >
              ABOUT US
            </Link>
          </div>
          <div className={styles.LinkBlockRight}>
            <div className={styles.LinkBlock}>
              <div>FOLLOW US</div>
              <Link
                href="https://twitter.com/sblivesports"
                className={cn(styles.TopIcon, styles.TopIconTwitter)}
                target="_blank"
                rel="noopener"
              />
              <Link
                href="https://www.facebook.com/sblivesports"
                className={cn(styles.TopIcon, styles.TopIconFacebook)}
                target="_blank"
                rel="noopener"
              />
              <Link
                href="https://www.instagram.com/sblivesports/"
                className={cn(styles.TopIcon, styles.TopIconInstagram)}
                target="_blank"
                rel="noopener"
              />
              <Link
                href="https://www.tiktok.com/@sblivesports"
                className={cn(styles.TopIcon, styles.TopIconTikTok)}
                target="_blank"
                rel="noopener"
              />
              <Link
                href="https://www.youtube.com/c/SBLiveSports"
                className={cn(styles.TopIcon, styles.TopIconYouTube)}
                target="_blank"
                rel="noopener"
              />
              <Link
                href="https://www.linkedin.com/company/sblive"
                className={cn(styles.TopIcon, styles.TopIconLinkedIn)}
                target="_blank"
                rel="noopener"
              />
            </div>
            <div className={styles.LinkBlock}>
              <div>Download the app</div>
              <Clickable
                href="https://apple.co/3vgk9FN"
                className={cn(styles.TopIcon, styles.TopIconApple)}
                target="_blank"
                rel="noopener"
              />
              <Clickable
                href="https://bit.ly/3l33PDC"
                className={cn(styles.TopIcon, styles.TopIconAndroid)}
                target="_blank"
                rel="noopener"
              />
            </div>
            <Link className={styles.LinkBlock} href={makeHomePath()} aria-label="SBLive Sports Home">
              <div>Powered by</div>
              <img src={SBLiveLogoWhite} alt="SBLlive" />
            </Link>
          </div>
        </div>
      </ScreenSizes>
      <ScreenSizes show={ScreenSize.SMALL}>
        <div className={styles.TopNavMobile}>
          <FlexContainer className={styles.TopMobileLogo}>
            <Link href={makeHomePath()} className={styles.SBLiveLogo} aria-label="SBLive Sports Home">
              <SBLiveOnSI />
            </Link>
            <div className={styles.PoweredBy}>
              Powered By
              <img src={SBLiveLogoWhite} alt="SBLive" />
            </div>
          </FlexContainer>
          {userData.isResolved && (
            <div className={styles.TopNavRight}>
              <div onClick={() => handleMobileMenuClick('search')}>
                <SearchIcon height={18} width={18} className="" />
              </div>
              {isLoggedIn ? (
                <>
                  <div onClick={() => handleMobileMenuClick('favorites')}>
                    <Star height={18} width={18} />
                  </div>
                  <div onClick={() => handleMobileMenuClick('account')}>
                    <Account height={18} width={18} className="" />
                  </div>
                </>
              ) : (
                <div
                  data-automation-id="login"
                  className={cn(styles.NavButton, styles.BlueNavButton, styles.SignInButton)}
                  onClick={handleOpenLoginModal}
                >
                  Sign In
                </div>
              )}
            </div>
          )}
        </div>
      </ScreenSizes>
      <div className={styles.BottomNav}>
        <ScreenSizes hide={ScreenSize.SMALL}>
          <Link href={makeHomePath()} aria-label="SBLive Sports Home" className={styles.SBLiveLogo}>
            <SBLiveOnSI />
          </Link>
        </ScreenSizes>
        <ScreenSizes show={ScreenSize.SMALL}>
          <ImageSelect component={<HeaderStatesMenu />} handleClick={() => isSmallScreen && handleMobileMenuClick('states')}>
            <div className={cn(styles.NavButton, styles.StatesButton)}>
              <StateFlag state={state} className={styles.State} />
              {state?.abbrev || 'NAT.'}
            </div>
          </ImageSelect>
        </ScreenSizes>
        <div className={styles.SportOptions}>
          <GenderSportLink genderSportSlug={FOOTBALL} />
          <ScreenSizes show={ScreenSize.SMALL}>
            {genderSport && !inDefaultSportsMobile ? (
              <GenderSportLink genderSportSlug={genderSport.slug} />
            ) : (
              <GenderSportLink genderSportSlug={BOYS_BASKETBALL} />
            )}
          </ScreenSizes>
          <ScreenSizes hide={ScreenSize.SMALL}>
            <GenderSportLink genderSportSlug={BOYS_BASKETBALL} />
          </ScreenSizes>
          <ScreenSizes show={[ScreenSize.LARGE, ScreenSize.EXTRA_LARGE]}>
            <GenderSportLink genderSportSlug={GIRLS_BASKETBALL} />
            <GenderSportLink genderSportSlug={BASEBALL} />
          </ScreenSizes>
          <ScreenSizes show={ScreenSize.EXTRA_LARGE}>
            <GenderSportLink genderSportSlug={SOFTBALL} />
            {inDefaultSports || !genderSport ? (
              <GenderSportLink genderSportSlug={GIRLS_VOLLEYBALL} />
            ) : (
              <GenderSportLink genderSportSlug={genderSport.slug} />
            )}
          </ScreenSizes>
        </div>
        <ImageSelect
          component={<HeaderGenderSportsMenuNew />}
          className={styles.SportsImageSelect}
          isRight
          handleClick={() => isSmallScreen && handleMobileMenuClick('sports')}
        >
          <Dots color="#fff" height={6} width={24} className="" />
        </ImageSelect>
        {userData.isResolved && (
          <ScreenSizes hide={ScreenSize.SMALL}>
            <div className={styles.BottomNavRight}>
              <ImageSelect isCenter component={<HeaderStatesMenu />} handleClick={() => isSmallScreen && handleMobileMenuClick('states')}>
                <div className={cn(styles.NavButton, styles.StatesButton)}>
                  <StateFlag state={state} className={styles.State} />
                  {state?.abbrev || 'NAT.'}
                </div>
              </ImageSelect>
              <div className={styles.NavSearch}>
                <Search
                  onSearch={handleSearch}
                  className={styles.NavSearchComponent}
                  viewAllLink={name => makeUrl('/schools', { name })}
                  displayParser={displayParser}
                />
              </div>
              {isLoggedIn ? (
                <>
                  <ImageSelect
                    autoId="favoritesBtn"
                    className={cn(styles.NavButton, styles.NavButtonRight)}
                    isRight
                    component={<HeaderFavoritesMenuNew favorites={favorites} />}
                  >
                    <Star height={14} width={14} />
                  </ImageSelect>
                  <ImageSelect
                    autoId="accountBtn"
                    className={cn(styles.NavButton, styles.BlueNavButton, styles.NavButtonRight)}
                    isRight
                    component={
                      <HeaderAccountMenuNew handleOpenPassword={handleOpenPassword} handleOpenAccountDelete={handleOpenAccountDelete} />
                    }
                  >
                    <Account height={14} width={14} className="" />
                  </ImageSelect>
                </>
              ) : (
                <div
                  data-automation-id="login"
                  className={cn(styles.NavButton, styles.BlueNavButton, styles.SignInButton)}
                  onClick={handleOpenLoginModal}
                >
                  Sign In
                </div>
              )}
            </div>
          </ScreenSizes>
        )}
      </div>
    </nav>
  );
}
